@font-face {
  font-family: "neue-haas-grotesk-display";
  src: url("https://use.typekit.net/af/384d9b/00000000000000007735bb6a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/384d9b/00000000000000007735bb6a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/384d9b/00000000000000007735bb6a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-style: normal;
  font-weight: 700;
  font-display: auto;
}

@font-face {
  font-family: "freight-display-pro";
  src: url("https://use.typekit.net/af/b5dc69/000000000000000077359f9c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/b5dc69/000000000000000077359f9c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/b5dc69/000000000000000077359f9c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-display: auto;
}

.closed-content {
  text-align: center;
  padding-top: 30px;
}

.closed-content h1 {
  font-family: neue-haas-grotesk-display;
  font-size: 32.0112px;
  font-weight: 700;
  color: #006f91;
  margin-top: 60px;
}

.closed-content h2 {
  font-family: freight-display-pro;
  font-size: 24.5px;
  font-weight: 400;
  color: #006f91;
}

.closed-content h3 {
  font-family: freight-display-pro;
  font-size: 18px;
  font-weight: 400;
  color: #006f91;
  margin-top: 80px;
}