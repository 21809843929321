.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.app-layout {
  min-height: 100vh;
}

// Layout header/content/footer
.app-layout>section.ant-layout {
  flex: 1 1 auto;
  overflow: auto;
  height: 100vh;
}

// Header {
.ant-layout-header,
.ant-menu.ant-menu-dark {
  background-color: #006f91;
}


.ant-layout-header .logo {
  float: left;
  max-height: 43px;
  margin: 10px 24px 10px 0;
}

.ant-layout-header .menu {
  width: 65%;
  float: left;
}

.ant-layout-header .logout {
  float: right;
}

// Layout Content
.ant-layout-content {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.main-content-wrapper {
  padding: 32px 32px;
  margin: 32px 32px 0 32px;
  flex: 1;
  background-color: #fff;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, .03);
  border-radius: 10px;
}

// Layout Footer
.ant-layout-footer {
  border-top: solid 1px #e8e8e8;
  text-align: center;
}

.ant-layout-footer {
  text-align: center;
  padding: 14px 32px;
  color: #4d5f71;
  font-size: 14px;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  margin-top: 30px;

  img {
    max-height: 14px;
  }
}

// Info modal
.info-modal .ant-modal-confirm-content>div {
  text-align: center;
  margin-top: 20px;
}

.info-modal .ant-modal-confirm-content img {
  max-width: 80px;
  margin-bottom: 15px;
}

.info-modal .ant-modal-confirm-btns {
  display: none;
}

// Menu
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item:hover {
  background-color: rgba(0, 0, 0, .2);
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item>a,
.ant-menu-dark .ant-menu-item>span>a {
  color: #ffffff;
}