.register-form-container {
  height: 100%;
  overflow: hidden;
  background-image: url('assets/register-background.jpg');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.85;
}

.register-form-scroll {
  overflow-y: auto;
  height: 100%;
  padding-top: 20px;
  padding-left: calc(50% - 425px);
}

.register-form {
  background-color: #FFF;
  max-width: 850px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin-bottom: 30px;
  padding: 40px;
}

.bienvenue-logo {
  margin-bottom: 25px;
}

.register-form h4 {
  margin-bottom: 30px !important;
  font-weight: normal !important;
}

.steps-content {
  margin-top: 30px;
}

.ant-steps-label-vertical .ant-steps-item-content {
  margin-top: 0px !important;
}

.success-message {
  text-align: center;
  margin-bottom: 25px;
}

.register-form-footer img {
  max-width: 150px;
  max-height: 70px;
}

.register-form-footer .logo2 {
  text-align: right;
}

.register-form-footer .text {
  text-align: center;
}

.payment-left-col {
  text-align: right;
  font-weight: 700;
}

.payment-row {
  margin-top: 7px;
}

.ant-upload-drag-icon svg {
  color: #40a9ff;
  font-size: 48px;
}

pre {
  font-size: 10px !important;
  line-height: 10px;
}

.ant-btn svg {
  margin-right: 10px;
}

.ant-btn-icon-only svg {
  margin-right: 0;
}