body,
#root {
  height: 100%;
}

@import '~antd/dist/antd.css';

@import './assets/NoirPro/stylesheet.css';

h1,
h2,
h3,
h4 {
  font-weight: lighter;
  color: #006f91;
}

body {
  font-size: 14px;
  font-family: "NoirPro", sans-serif;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: "tnum", "tnum";
  color: #4d5f71;
}

.ant-btn-primary {
  background: #006f91;
}

.ant-result-icon>svg {
  font-size: 72px;
}

.ant-modal-confirm-info .ant-modal-confirm-body>.svg-inline--fa {
  color: #006f91;
}

.ant-modal-confirm-body>.svg-inline--fa {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  color: #4d5f71;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  color: #006f91;
}

// Page header
.ant-page-header-heading-title {
  font-weight: lighter;
  font-size: 24px;

  >svg:first-child {
    margin-right: 6px;
  }
}

.ant-table-expanded-row.ant-table-expanded-row-level-1>.ant-table-cell {
  border-left: 4px solid rgb(0, 111, 145);
}

.row-clickable {
  cursor: pointer;
}